// Banners services
import { Banner, Store, Vendor } from "artisn/types";

import { getState } from "services/state";

/**
 * Fetches the banners from a given category.
 *
 * @param {string} bannerCategoryName The category name to get the banner data
 * @returns {Banner[]} The list of banners of the given category
 */
export const fetchBanners = async (
  bannerCategoryName: string,
  vendorId: Vendor["id"],
  storeId?: Store["storeId"]
): Promise<Banner[]> => {
  const { shouldMock, axiosDefault, accountId } = getState();

  try {
    if (!shouldMock) {
      const { data } = await axiosDefault.get(
        `api/banners?account_id=${accountId}&vendor_id=${vendorId}&banner_category_name=${bannerCategoryName}`
      );
      return data.data;
    } else {
      let banners: Banner[] = [];
      if (bannerCategoryName === "hornero_promociones_banners") {
        const { mockPromotionalBanners } = await import(
          "./banners.service.mock"
        );
        banners = mockPromotionalBanners;
      } else {
        const { mockBanners } = await import("./banners.service.mock");
        banners = mockBanners;
      }

      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(banners);
        }, 1000);
      });
    }
  } catch (e) {
    throw new Error(e.message);
  }
};
