import styled from "styled-components";

import { SliderCardStyledProps as Props } from "./SliderCard.types";

const SliderCardStyled = styled.div<Props>`
  position: relative;
  cursor: pointer;
  border-radius: 2rem;
  overflow: hidden;

  .SliderCard {
    &__image {
      .Image__img {
        object-fit: cover;
      }
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      padding: 4rem 3.2rem;

      &-badge {
        max-width: 50%;
        padding: 0.4rem 0;
        text-align: center;
        color: var(--palette-text-white);
        border-radius: 2.4rem;
        background-color: var(--palette-gray-s0-l20-a70);
      }

      &-bottom {
        margin-top: auto;
      }

      &-title {
        max-width: 16.8rem;
        padding-bottom: 3.2rem;
        line-height: 2.8rem;
        font-size: 2rem;
        color: var(--palette-text-white);
        text-shadow: 0 0 0.8rem var(--palette-black);
      }

      &-button {
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 1.6rem;
        font-weight: 600;
        color: var(--palette-white);
        background-color: transparent;
        border: 0;
        text-shadow: 0 0 0.2rem var(--palette-black);

        &:hover:not([disabled]) {
          color: var(--palette-gray-s0-l92);
        }
      }

      &-svg {
        margin-left: 2rem;
      }
    }
  }
`;

export default SliderCardStyled;
