import React from "react";
import { Image } from "artisn-ui-react";

import Styles from "./SliderCard.styles";
import { SliderCardProps as Props } from "./SliderCard.types";
import Button from "components/global/Button/Button";
import { transformMockImageSize } from "utils/image.utils";

import ArrowRight from "../../../../public/assets/images/arrow-right.svg";

const SliderCard: React.FC<Props> = props => {
  const { images, name, badge = "Categoría", onClick, className } = props;
  const { arrowText, imageConfig, showContent = true } = props;
  const [firstImage] = images;
  const { height, width } = imageConfig;

  const image = transformMockImageSize(firstImage, {
    imageHeight: height,
    imageWidth: width
  });

  return (
    <Styles className={`SliderCard ${className}`}>
      <div onClick={onClick}>
        {firstImage ? (
          <Image
            className="SliderCard__image"
            image={image}
            config={imageConfig}
            alt={name}
            placeholder="blur"
            errorImage="https://res.cloudinary.com/dphyzfzpu/image/upload/v1657499038/Hornero-prod/placeholder_fft6iy.jpg"
          />
        ) : null}
        {showContent ? (
          <div className="SliderCard__content">
            <div className="SliderCard__content-badge">{badge}</div>
            <div className="SliderCard__content-bottom">
              <p className="SliderCard__content-title">{name}</p>
              <Button className="SliderCard__content-button" type="LINK">
                {arrowText} <ArrowRight className="SliderCard__content-svg" />
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

SliderCard.defaultProps = {};

export default SliderCard;
