import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { TabsMenu as TabsMenuUI } from "artisn-ui-react";

import Styles from "./TabsMenu.styles";
import { TabsMenuProps as Props } from "./TabsMenu.types";
import LoyaltyTabsMenuItem from "../navigation/LoyaltyTabsMenuItem/LoyaltyTabsMenuItem";
import UserTabsMenuItem from "../navigation/UserTabsMenuItem/UserTabsMenuItem";
import CouponsTabsMenuItem from "../navigation/CouponsTabsMenuItem/CouponsTabsMenuItem";
import ShopTabsMenuItem from "../navigation/ShopTabsMenuItem/ShopTabsMenuItem";
import CONSTANTS from "config/constants";
import useTalkShop from "contexts/talkShop/talkShop/talkShop.context.hooks";

const { WITH_LOYALTY, WITH_STORE_COUPONS } = CONSTANTS.FEATURE_FLAGS;
const { WITH_TALK_SHOP } = CONSTANTS.FEATURE_FLAGS;
const { tablet } = CONSTANTS.BREAKPOINTS;

const TabsMenu: React.FC<Props> = props => {
  const { children } = props;
  const { pathname } = useRouter();
  const [active, setActive] = useState(-1);
  const { talkShopProvider, talkShopIdentifier } = useTalkShop();
  const talkShop = WITH_TALK_SHOP && talkShopProvider && talkShopIdentifier;

  useEffect(() => {
    if (pathname.startsWith("/categories")) {
      setActive(0);
    } else if (pathname.startsWith("/loyalty")) {
      setActive(1);
    } else if (pathname.startsWith("/coupons")) {
      setActive(2);
    } else if (pathname.startsWith("/profile")) {
      setActive(3);
    }
  }, [pathname]);

  const tabs = [
    <ShopTabsMenuItem key={0} active={active === 0} />,
    WITH_LOYALTY ? <LoyaltyTabsMenuItem key={1} active={active === 1} /> : null,
    WITH_STORE_COUPONS ? (
      <CouponsTabsMenuItem key={2} active={active === 2} />
    ) : null,
    <UserTabsMenuItem key={3} active={active === 3} />
  ];

  return (
    <Styles className="TabsMenu" talkShop={!!talkShop}>
      <TabsMenuUI tabs={tabs} maxMobileWidth={tablet + 1}>
        {children}
      </TabsMenuUI>
    </Styles>
  );
};

TabsMenu.defaultProps = {};

export default TabsMenu;
