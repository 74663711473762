import styled from "styled-components";

import { TabsMenuStyledProps as Props } from "./TabsMenu.types";

const TabsMenuStyled = styled.div<Props>`
  .TabsMenu {
    &__tabs {
      &__item {
        min-width: 6rem;
      }
    }

    &__bar {
      padding-bottom: 0.8rem;
      visibility: ${props => (props.talkShop ? `hidden` : `unset`)};
      z-index: ${props => (props.talkShop ? `-1` : `4`)};
    }
  }
`;

export default TabsMenuStyled;
