import React from "react";
import { useRouter } from "next/router";
import { TabsMenu as TabsMenuUI } from "artisn-ui-react";

import Styles from "./ShopTabsMenuItem.styles";
import { ShopTabsMenuItemProps as Props } from "./ShopTabsMenuItem.types";
import useProducts from "contexts/products/products.context.hooks";

import MenuSVG from "../../../../../public/assets/images/TabsMenu/menu.svg";

const { Item } = TabsMenuUI;

const ShopTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active } = props;
  const { push, pathname } = useRouter();
  const { setShowCategoryList } = useProducts();

  const onClickHandler = async () => {
    if (pathname.startsWith("/categories")) {
      setShowCategoryList(true);
      return;
    }
    await push("/categories");
  };

  return (
    <Styles className={`ShopTabsMenuItem ${className}`} active={active}>
      <Item
        icon={MenuSVG}
        title={hideTitle ? undefined : "Menú"}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

ShopTabsMenuItem.defaultProps = {
  className: ""
};

export default ShopTabsMenuItem;
