import CONSTANTS from "config/constants";
import styled from "styled-components";

import { BannersSliderStyledProps as Props } from "./BannersSlider.types";

const { tablet } = CONSTANTS.BREAKPOINTS;

const BannersSliderStyled = styled.div<Props>`
  .BannersSlider {
    &__title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      padding-bottom: 3.2rem;
    }

    &__slider {
      max-width: 100%;
      width: 100%;

      &__title {
        margin-top: 4rem;
        margin-bottom: 3.2rem;
        font-size: 3.2rem;
        font-weight: 800;
        color: var(--palette-black-s0-l10);

        span {
          color: var(--palette-primary);
        }
      }

      &__button-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      .slick-arrow {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--palette-lightgrey-s5-l90);
        z-index: 1;

        ::before {
          display: none;
        }

        svg {
          width: 1.6rem;
          height: 1.6rem;

          path {
            fill: var(--palette-primary);
          }
        }
      }

      .slick-prev {
        left: 0;
      }

      .slick-next {
        right: 1.6rem;

        svg {
          transform: rotate(180deg);
        }
      }

      .slick-list {
        @media (min-width: ${tablet - 1}px) {
          &:hover::before {
            box-shadow: 1.2rem 0 2rem 0 #ffffff, 1.2rem 0 1.2rem 0 #ffffff !important;
          }
        }
      }

      &--blur {
        .slick-list::before {
          box-shadow: 1.2rem 0 2rem 0 #ffffff, 1.2rem 0 1.2rem 0 #ffffff !important;
        }
      }

      .slick-list::before {
        content: "";
        position: absolute;
        width: 2rem;
        left: -2rem;
        top: -1rem;
        height: calc(100% + 2rem);
        z-index: 1;
        transition: all 0.5s ease 0s;
        box-shadow: 1.2rem 0 2rem 0 rgba(255, 255, 255, 0),
          1.2rem 0 1.2rem 0 rgba(255, 255, 255, 0);
      }
    }
  }

  .Image__img,
  .SliderCard__image {
    width: 100%;
    height: 100%;
  }
`;

export default BannersSliderStyled;
