import React, { useEffect, useState } from "react";
import { Slider } from "artisn-ui-react";

import Styles from "./BannersSlider.styles";
import { BannersSliderProps as Props } from "./BannersSlider.types";
import { useFetchBanners } from "services/banners/banners.service.hooks";
import SliderCard from "../SliderCard/SliderCard";
import { homeSliderImageConfig } from "components/home/Home/Home.helpers";
import useWindowSize from "hooks/useWindowSize";
import CONSTANTS from "config/constants";
import { Banner } from "artisn/types";
import { goToBannerUrl } from "./BannersSlider.helpers";
import useProducts from "contexts/products/products.context.hooks";

import ArrowSVG from "../../../../public/assets/images/chevron-left-black.svg";

const { BREAKPOINTS, FEATURE_FLAGS } = CONSTANTS;
const { tablet } = BREAKPOINTS;
const { WITH_PRODUCT_MODAL } = FEATURE_FLAGS;

const BannersSlider: React.FC<Props> = props => {
  const { bannerCategoryName, title, className } = props;
  const { data: banners } = useFetchBanners(bannerCategoryName);
  const bannersLength = banners?.length;
  const { width } = useWindowSize();
  const isMobile = width <= tablet;
  const { setOpenProductModal } = useProducts();
  const [sliderBlur, setSliderBlur] = useState(false);

  const renderTitle = () => {
    if (typeof title === "string") {
      return <h2 className="BannersSlider__title">{title}</h2>;
    }
    return title;
  };

  const clickHandler = (banner: Banner) => {
    const { product_id } = banner;
    if (!WITH_PRODUCT_MODAL) {
      goToBannerUrl(banner);
      return;
    }
    if (product_id) {
      setOpenProductModal(`${product_id}`);
    }
  };

  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onMouseDown={() => {
          setSliderBlur(true);
          onClick?.();
        }}
        onMouseUp={() => {
          setSliderBlur(false);
        }}
      >
        <div className="BannersSlider__slider__button-content">
          <ArrowSVG viewBox="0 0 22 22" />
        </div>
      </div>
    );
  };

  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onMouseDown={() => {
          setSliderBlur(true);
          onClick?.();
        }}
        onMouseUp={() => {
          setSliderBlur(false);
        }}
      >
        <div className="BannersSlider__slider__button-content">
          <ArrowSVG viewBox="0 0 22 22" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!bannersLength)
      console.warn(
        "Warning: Unable to display. There are no images to display on the carousel "
      );
  }, [bannersLength]);

  if (!bannersLength) return null;

  return (
    <Styles className={`BannersSlider ${className}`}>
      {renderTitle()}
      <Slider
        className={`BannersSlider__slider ${
          sliderBlur ? "BannersSlider__slider--blur" : ""
        }`}
        slideWidth={isMobile ? 290 : 375}
        width={1200}
        height={isMobile ? 317 : 405}
        gap={24}
        arrows={true}
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        infinite
      >
        {banners?.map(banner => {
          const images =
            [
              {
                name: banner.image_file_name,
                url: banner.image_url,
                bucket: banner.image_bucket,
                cloudFrontUrl: banner.image_cloud_front_url,
                key: banner.image_key
              }
            ] ?? [];

          return (
            <SliderCard
              name={banner?.banner_title}
              images={images}
              arrowText="Comprar ahora"
              onClick={() => clickHandler(banner)}
              imageConfig={homeSliderImageConfig}
              key={banner?.banner_category_id}
              showContent={false}
            />
          );
        })}
      </Slider>
    </Styles>
  );
};

BannersSlider.defaultProps = {
  className: ""
};

export default BannersSlider;
