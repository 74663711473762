// BannersSlider helper functions and data
import router from "next/router";
import { Storage } from "@capacitor/storage";
import { Banner } from "artisn/types";
import CONSTANTS from "config/constants";
import { sanitizeText } from "utils/seo.utils";

const { STORAGE } = CONSTANTS;
const { CATEGORY_TOKEN } = STORAGE;

export const goToBannerUrl = async (banner: Banner) => {
  const { category_id: categoryId, category_name: categoryName } = banner;
  const { product_id: productId, product_name: productName } = banner;
  const { banner_type: type, banner_url: bannerUrl } = banner;
  if (bannerUrl) {
    window.open(bannerUrl, "_blank");
    return;
  }
  // @ts-ignore
  if (type === "CATEGORY" && categoryName) {
    try {
      await Storage.set({
        key: CATEGORY_TOKEN,
        value: JSON.stringify(categoryId)
      });
    } catch (e) {
      console.error("Cannot save category in storage");
    }
    const sanitizedName = sanitizeText(categoryName);
    router.push(`/categories/${categoryId}/${sanitizedName}`);
  }
  if (type === "PRODUCT") {
    // @ts-ignore
    const sanitizedName = sanitizeText(productName);
    router.push(`/products/${productId}/${sanitizedName}`);
  }
};
