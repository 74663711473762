// Banners service hooks
import { useQuery } from "react-query";

import { fetchBanners } from "./banners.service";
import useVendors from "contexts/vendors/vendors.hooks";
import { notify } from "utils/common.utils";
import useStores from "contexts/stores/stores.context.hooks";

export const useFetchBanners = (bannerCategoryName: string) => {
  const { selectedVendorId } = useVendors();
  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};

  return useQuery(
    ["banners", bannerCategoryName, selectedVendorId, storeId],
    () => fetchBanners(bannerCategoryName, selectedVendorId, storeId!),
    {
      staleTime: 30 * 1000 * 60,
      onError: (e: Error) => {
        if (!e) return;
        notify(e, "Get banners request");
      },
      enabled: !!storeId && !!selectedVendorId
    }
  );
};
